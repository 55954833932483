'use strict';
// ------------------------------------- index.js

// CSS
import './../scss/global.scss';

// index.html goldfish animation
import './animation';

// // portfolio/img_grid
// import './../portfolio/img_grid/js/images-grid';

import jQuery from 'jquery';
// ドルマークに参照を代入(慣習的な $ を使うため)
const $ = jQuery;

/* ------------------------------
ランダムサークル
------------------------------ */
$(function () {
  var container = $('.js-circle-container');
  var circle = $('.js-circle');
  var interval = 100; // 発生間隔
  var rate = 25; // 発生確率
  var timeout = 5000; // 消えるまでの時間
  var padding = 50; // 描画範囲の余白
  var minX = 0 + padding;
  var minY = 0 + padding;
  var maxX = container.width() - padding;
  var maxY = container.height() - padding;
  var timer = false;

  $(window).resize(function () {
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      maxX = container.width() - padding;
      maxY = container.height() - padding;
    }, 200);
  });

  setInterval(randomCircle, interval);

  function randomCircle () {
    var num = getRandomInt(1, 100);
    var x = getRandomInt(minX, maxX);
    var y = getRandomInt(minY, maxY);
    var style = {
      top: y,
      left: x
    };

    if (num <= rate) {
      var target = circle.clone().prependTo(container);
      target.css(style).show();
      setTimeout(function () {
        target.remove();
      }, timeout);
    }
  }
});

/* ------------------------------
ランダム金魚
------------------------------ */
// ノーマル
$(function () {
  var fish = $('.js-fish');
  var interval = 1000; // 発生間隔
  var rate = 10; // 発生確率
  var timeout = 8000; // 消えるまでの時間
  setInterval(function () {
    randomFIsh(fish, rate, timeout);
  }, interval);
});

// ボス
$(function () {
  var fish = $('.js-pop-eyed');
  var interval = 2000; // 発生間隔
  var rate = 15; // 発生確率
  var timeout = 9000; // 消えるまでの時間
  setInterval(function () {
    randomFIsh(fish, rate, timeout);
  }, interval);
});

/* ------------------------------
マーカーをランダムで表示する関数
------------------------------ */
function randomFIsh (fish, rate, timeout) {
  fish.each(function () {
    var num = getRandomInt(1, 100);
    var target = $(this);
    if (num <= rate && target.is(':hidden')) {
      target.show();
      setTimeout(function () {
        target.fadeOut('slow');
      }, timeout);
    }
  });
}

/* ------------------------------
整数をランダムで返す関数
------------------------------ */
function getRandomInt (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
